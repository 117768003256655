<template>
  <v-autocomplete
    v-model="channelIds"
    :deletable-chips="!filter"
    :error-messages="error"
    :items="channels"
    :multiple="Array.isArray(channelIds)"
    :prepend-inner-icon="filter ? 'mdi-target' : ''"
    clearable
    dense
    hide-details
    item-text="name"
    item-value="id"
    label="Canaux"
    outlined
    @change="$emit('update:error', null)"
  >
    <template v-slot:selection="data">
      <v-chip
        v-if="Array.isArray(channelIds)"
        :input-value="data.selected"
        close
        small
        v-bind="data.attrs"
        @click="data.select"
        @click:close="remove(data)"
      >
        {{ data.item.name }}
      </v-chip>
      <template v-else>
        {{ data.item.name }}
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import inputMixin from '@/mixins/input'
import { mapState } from 'vuex'

export default {
  mixins: [ inputMixin ],
  props: {
    value: {
      type: [Array, String],
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    required: Boolean,
    filter: Boolean,
  },
  computed: {
    ...mapState(['channels']),
    channelIds: {
      get () { return this.value },
      set (value) { this.$emit('input', value) },
    },
  },
  methods: {
    remove (data) {
      if (Array.isArray(this.channelIds)) {
        this.channelIds = this.channelIds.filter(id => id !== data.item.id)
      } else {
        this.channelIds = null
      }
    },
  },
}
</script>

import './plugins/axios'
import './plugins/base'
import App from './App.vue'
import ConfirmModal from '@/components/base/Modal/ConfirmModal.vue'
import i18n from './i18n'
import moment from 'moment'
import router from './router'
import store from './store'
import Vue from 'vue'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

Vue.filter('dateSimple', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})
Vue.filter('date', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
  }
})
Vue.filter('dateFull', function (value) {
  if (value) {
    return moment(String(value)).format('dddd DD MMMM YYYY HH:mm:ss')
  }
})
Vue.filter('dateAdd15d', function (value) {
  if (value) {
    return moment(String(value)).add('15', 'd').format('DD/MM/YYYY')
  }
})

Vue.filter('currency', function (value) {
  if (typeof value !== 'number') {
    return value
  }
  let formatter = new Intl.NumberFormat('FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  })

  return formatter.format(value)
})

Vue.filter('rate', function (value) {
  if (typeof value !== 'number') {
    return value
  }
  let formatter = new Intl.NumberFormat('FR', {
    style: 'percent',
    minimumFractionDigits: 2,
  })

  return formatter.format(value / 100)
})

Vue.filter('discount', function (value) {
  if (typeof value !== 'number') {
    return value
  }

  return '-' + value + '%'
})

Vue.filter('truncate', function (value, length = 100, clamp = '...') {
  if (typeof value !== 'string') {
    return value
  }

  return value.length > length ? value.slice(0, length) + clamp : value
})

Vue.component('ConfirmModal', ConfirmModal)

Vue.prototype.$variables = window.variables

new Vue({
  vuetify,
  store,
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')

<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          small
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="grey lighten-1">
            mdi-cloud-sync
          </v-icon>
        </v-btn>
      </template>
      <div>
        {{ value.provider.name }}
        <br>
        {{ value.key }}
      </div>
    </v-tooltip>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
}
</script>

<template>
  <div>
    <v-dialog
      :value="openAddAttribute"
      width="auto"
      @input="openAddAttribute = false"
    >
      <v-card width="300">
        <v-form
          ref="form-attr"
          v-model="valid"
        >
          <v-card-text class="d-flex align-center pa-3">
            <v-text-field
              v-model="newAttribute"
              :rules="[rules.required, rules.min]"
              hide-details
              clearable
              dense
              outlined
            >
              <template v-slot:append-outer>
                <v-btn
                  v-if="addAttribute"
                  raised
                  icon
                  color="green darken-1"
                  class="white--text"
                  small
                  @click="addAttribute"
                >
                  <v-icon small>
                    mdi-check-outline
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    <div class="d-flex align-center">
      <v-autocomplete
        v-model="attributes"
        :label="inputLabel($t('views.products.forms.input.' + kind))"
        :items="items"
        hide-details
        clearable
        dense
        outlined
        deletable-chips
        multiple
        item-text="name"
        item-value="id"
        append-icon="mdi-plus-circle"
        @click:append="openAddAttribute = true"
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            small
            close
            @click="data.select"
            @click:close="removeAttribute(data.item)"
          >
            <div :class="{custom: data.item.custom}">
              <LocalizedTextInfo
                v-if="data.item.localized_name"
                :translation-key="data.item.localized_name.translation_key"
                :translations="data.item.translations"
              >
                {{ data.item.localized_name.value }}
              </LocalizedTextInfo>
              <template v-else>
                {{ data.item.name }}
              </template>
            </div>
          </v-chip>
        </template>
      </v-autocomplete>
    </div>
  </div>
</template>

<script>
import inputMixin from '@/mixins/input'
import LocalizedTextInfo from '@/components/core/LocalizedTextInfo.vue'
import { mapState } from 'vuex'

export default {
  components: { LocalizedTextInfo },
  mixins: [ inputMixin ],
  props: {
    value: {
      type: Array,
      required: false,
      default: null,
    },
    kind: {
      type: String,
      required: false,
      default: null,
    },
    required: Boolean,
    taxonomyAttributes: {
      type: Array,
      default: null,
    },
  },
  data () {
    return {
      openAddAttribute: false,
      newAttribute: '',
      additionalAttributes: [],
      valid: false,
      rules: {
        required: value => !!value || this.$i18n.t('global.required'),
        min: v => v.length >= 1 || '1 caractère min',
        
      },
    }
  },
  computed: {
    ...mapState(['taxonomies']),
    filteredTaxonomies () {
      if (!Array.isArray(this.taxonomies[this.kind])) {
        return null
      }

      return this.taxonomies[this.kind]
        ?.filter(t => !this.taxonomyAttributes || this.taxonomyAttributes.includes(t.attribute.id)) ?? []
    },
    attributes: {
      get () { return this.value
        ?.filter(attribute =>
          attribute.kind === this.kind &&
          (
            !attribute.taxonomy_id ||
            this.filteredTaxonomies?.map(t => t.id).includes(attribute.taxonomy_id)
          )
        )
        ?.map(attribute => attribute.taxonomy_id ?? attribute.name ) ?? []
      },
      set (value) {
        const attributes = [
          ... this.value.filter((v) => v.kind !== this.kind),
          ...value.map(v => {
            const taxonomy = this.filteredTaxonomies?.find(elt => elt.id === v) || null
            if (taxonomy !== null) {
              return {
                name: taxonomy.name,
                kind: taxonomy.kind,
                taxonomy_id: taxonomy.id,
              }
            }

            return {
              name: v,
              kind: this.kind,
            }
          }),
        ]

        this.$emit('input', attributes)
      },
    },
    items () {
      return [
        ...this.filteredTaxonomies?.map(item => ({ id: item.id, name: item.name })) ?? [],
        ...(
            this.value?.map(item => (!item.taxonomy_id && item.kind === this.kind
              ? {
                id: item.name,
                name: item.name,
                custom: true,
                localized_name: item.localized_name,
                translations: item.translations,
              }
              : null)
            ) ?? [])
          .filter(v => v),
        ...this.additionalAttributes.map(a => ({ id: a, name: a, custom: true })),
      ]
    },
  },
  watch: {
    taxonomyAttributes (value) {
      if (null === value) {
        return
      }

      this.$emit(
        'input', this.value.filter(attribute => (
          !attribute.taxonomy_id ||
          !this.filteredTaxonomies ||
          this.filteredTaxonomies.map(t => t.id).includes(attribute.taxonomy_id)
        ))
      )
    },
  },
  methods: {
    addAttribute() {
      this.$refs['form-attr'].validate()
      if (!this.valid) return

      this.additionalAttributes = [...this.additionalAttributes, this.newAttribute]
      this.attributes = [...this.attributes, this.newAttribute]
      
      this.newAttribute = null
      this.openAddAttribute = false
    },
    removeAttribute (item) {
      this.$emit(
        'input',
        this.value.filter((v) => {

          if (item.custom) {
            return v.taxonomy_id !== null || v.name !== item.id
          }
          if (!item.custom) {
            return v.taxonomy_id !== item.id
          }
        })
      )
    },
  },
}
</script>

<style scoped>
 .custom {
   font-style: italic;
 }
</style>

<template>
  <div>
    <v-dialog
      :value="openCustomizeAttribute"
      width="auto"
      @input="openCustomizeAttribute = false"
    >
      <v-card width="500">
        <v-form
          ref="form-custom-attr"
          v-model="form.valid"
        >
          <v-card-text
            class="pa-3"
          >
            <v-row>
              <v-col>
                <v-text-field
                  v-model="customAttributes.custom_text_label"
                  :label="inputLabel($t('views.products.forms.input.custom_text_label'))"
                  :rules="[form.rules.required]"
                  clearable
                  dense
                  outlined
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="customAttributes.custom_text_max_size"
                  type="number"
                  :label="inputLabel($t('views.products.forms.input.custom_text_max_size'))"
                  :rules="[form.rules.required]"
                  clearable
                  dense
                  outlined
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              raised
              color="green darken-1"
              class="white--text"
              @click="addAttribute"
            >
              <v-icon left>
                mdi-check-outline
              </v-icon>
              {{ $t('forms.buttons.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-card
      class="mb-4"
    >
      <v-card-title>
        Personnalisation du produit
        <v-spacer />
        <v-btn
          v-if="items.length > 0"
          icon
          large
          @click="clearCustomAttributes"
        >
          <v-icon
            large
            color="red lighten-1"
          >
            mdi-delete-circle-outline
          </v-icon>
        </v-btn>
        <v-btn
          icon
          large
          @click="openCustomizeAttribute = true"
        >
          <v-icon
            large
            color="blue lighten-1"
          >
            mdi-pencil-circle-outline
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        v-if="items.length > 0"
      >
        <v-simple-table>
          <tbody>
            <tr
              v-for="(item, index) in items"
              :key="`item-${index}-${item.key}`"
            >
              <td>{{ $t('views.products.forms.input.' + item.kind) }}</td>
              <td>{{ item.name }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-card-text
        v-else
      >
        Aucune personnalisation
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import inputMixin from '@/mixins/input'

export default {
  mixins: [ inputMixin ],
  props: {
    value: {
      type: Array,
      required: false,
      default: null,
    },
    kind: {
      type: String,
      required: false,
      default: null,
    },
    required: Boolean,
  },
  data () {
    return {
      openCustomizeAttribute: false,
      customAttributes: {
        custom_text_label: this.getAttributeValue('custom_text_label'),
        custom_text_max_size: this.getAttributeValue('custom_text_max_size'),
      },
      form: {
        valid: false,
        rules: {
          required: value => !!value || this.$i18n.t('global.required'),
          min: v => v.length >= 1 || '1 caractère min',
        },
      },
    }
  },
  computed: {
    items: {
      get() {
        return this.value?.filter(attribute => attribute.kind.startsWith(this.kind)) ?? []
      },
    },
  },
  methods: {
    getAttributeValue(kind) {
      return this.value?.find((v) => v.kind === kind)?.name ?? null
    },
    clearCustomAttributes() {
      let attributes = this.value.filter((v) => !v.kind.startsWith(this.kind))
      
      this.$emit('input', attributes)
      this.openCustomizeAttribute = false
    },
    addAttribute() {
      this.$refs['form-custom-attr'].validate()
      if (!this.form.valid) return

      let attributes = this.value.filter((v) => !v.kind.startsWith(this.kind))

      if (
        this.customAttributes.custom_text_label !== null
        && this.customAttributes.custom_text_label.trim() !== ''
        && this.customAttributes.custom_text_max_size !== null
        && this.customAttributes.custom_text_max_size.trim() !== ''
      ) {
        attributes = [
          ...attributes,
          {
            name: this.customAttributes.custom_text_label,
            kind: 'custom_text_label',
          },
          {
            name: this.customAttributes.custom_text_max_size,
            kind: 'custom_text_max_size',
          },
        ]
      }
      
      this.$emit('input', attributes)
      this.openCustomizeAttribute = false
    },
  },
}
</script>

<style scoped>
</style>
